<!--
 * @Description: 纠错
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-16 18:20:19
-->
<template>
  <el-scrollbar>
    <el-card class="box query">
      <el-form :model="query" ref="query" :inline="true">
        <el-form-item label="报备单号">
          <el-input v-model="query.no"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" round icon="search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item >
          <transfer></transfer>
        </el-form-item>
        
      </el-form>
    </el-card>
    <el-card class="box data" v-loading="loading">
      <el-tabs class="full" v-if="item && item.id">
        <el-tab-pane label="报备单">
          <el-descriptions :column="1">
            <el-descriptions-item label="系统编号">{{
              item.id
            }}</el-descriptions-item>
            <el-descriptions-item label="报备单号">{{
              item.hNumber
            }}</el-descriptions-item>
            <el-descriptions-item label="公司名称">{{
              item.company
            }}</el-descriptions-item>
            <el-descriptions-item label="带看项目">{{
              item.estateName
            }}</el-descriptions-item>
            <el-descriptions-item label="带看人"
              >{{ item.daikanName }}/{{
                item.daikanMobile
              }}</el-descriptions-item
            >
            <el-descriptions-item label="报备人"
              >{{ item.consumer_Name }}/{{
                item.consumer_Mobile
              }}</el-descriptions-item
            >
            <el-descriptions-item label="客户姓名">{{
              item.clientName
            }}</el-descriptions-item>
            <el-descriptions-item label="客户性别">{{
              item.sex
            }}</el-descriptions-item>
            <el-descriptions-item label="客户手机">{{
              item.clientMobile
            }}</el-descriptions-item>
            <el-descriptions-item label="到案人数">{{
              item.dueNum
            }}</el-descriptions-item>
            <el-descriptions-item label="到案时间">{{
              item.dueTime
            }}</el-descriptions-item>
            <el-descriptions-item label="报备备注">{{
              item.remark
            }}</el-descriptions-item>
            <el-descriptions-item label="报备时间">{{
              item.creationTime
            }}</el-descriptions-item>
            <el-descriptions-item label="状态">
              <el-link
                type="warning"
                :underline="false"
                v-if="item.curStatus == 0"
                >{{ item.cur_status_text }}</el-link
              >
              <el-link
                type="primary"
                :underline="false"
                v-else-if="item.curStatus == 1"
                >{{ item.cur_status_text
                }}{{ item.unknown ? "(结果未知)" : "" }}</el-link
              >
              <el-link
                type="success"
                :underline="false"
                v-else-if="item.curStatus == 2"
                >{{ item.cur_status_text }}</el-link
              >
              <el-link
                type="danger"
                :underline="false"
                v-else-if="item.curStatus == 3"
                >{{ item.cur_status_text }}</el-link
              >
              <el-link :underline="false" v-else>{{
                item.cur_status_text
              }}</el-link>
            </el-descriptions-item>
            <el-descriptions-item label="处理人"
              ><b>{{ item.modify_name }}({{ item.modifiedTime }})</b>
              <span v-if="item.isAcData"
                >{{ item.kefu_name }}({{ item.kefu_mobile }})</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="处理结果">{{
              item.resultContent
            }}</el-descriptions-item>
          </el-descriptions>
          <el-divider></el-divider>
          <el-form :model="form" ref="form" :rules="rules" label-width="80px">
            <el-form-item label="状态" prop="curStatus">
              <el-radio-group v-model="form.curStatus">
                <el-radio :label="1">已发开发商(结果未知)</el-radio>
                <el-radio :label="2">有效</el-radio>
                <el-radio :label="3">无效</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="处理结果">
              <el-col :span="10">
                <el-input
                  v-model="form.resultContent"
                  type="textarea"
                  placeholder=""
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="纠错原因">
              <el-col :span="10">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  placeholder=""
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" :loading="saving"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="修改公司">
          <el-form
            :model="company_rules"
            ref="form_company"
            :rules="company_rules"
            label-width="140px"
          >
            <el-form-item label="公司名称" prop="companyName">
              <el-col :span="10">
                <el-input v-model="form_company.companyName"></el-input
              ></el-col>
            </el-form-item>
            <el-form-item label="原因">
              <el-col :span="10">
                <el-input
                  v-model="form_company.remark"
                  type="textarea"
                  placeholder=""
                ></el-input>
              </el-col>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="onCompanySubmit"
                :loading="saving"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </el-scrollbar>
</template>

<script>
import Transfer from './transfer.vue';
export default {
  components: { Transfer },
  data() {
    return {
      loading: false,
      saving: false,
      query: {
        no: "",
      },
      item: {},
      form: {},
      form_company: {},
      rules: {
        curStatus: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      company_rules: {
        companyName: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  methods: {
    /**
     *
     */
    onSearch() {
      if (!this.query.no) {
        this.$message.warning("请输入报备单号");
        return;
      }
      this.loading = true;
      this.$http
        .get("admin/v1/report/get_by_no?no=" + this.query.no)
        .then((res) => {
          if (res.code == 0) {
            this.item = res.data;
            this.form = {
              id: this.item.id,
              curStatus: this.item.curStatus,
              resultContent: this.item.resultContent,
            };
            this.form_company = {
              id: this.item.id,
              companyName: this.item.company,
            };
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 修改状态
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/report/update/status", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.onSearch();
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },

    /**
     * 修改公司名称
     */
    onCompanySubmit() {
      this.$refs.form_company.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/report/update/company", this.form_company)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.onSearch();
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style>
.el-descriptions__cell {
  padding-bottom: 2px !important;
}
.el-descriptions__label:not(.is-bordered-label) {
  color: #999;
}
</style>
